import React, { lazy } from "react";
const quanLiTheXe = React.lazy(
  () => import("../components/organisms/pages/Admin/Quan_ly_the_xe")
);
const dangKyGiaHan = React.lazy(
  () => import("../components/organisms/pages/Admin/Dang_ky_gia_han")
);
const thongKeDoanhThu = React.lazy(
  () => import("../components/organisms/pages/Admin/Thong_ke_doanh_thu")
);
const cauHinhThanhToan = React.lazy(
  () => import("../components/organisms/pages/Admin/Cau_hinh_thanh_Toan")
);
const quanLyTaiKhoan = React.lazy(
  () => import("../components/organisms/pages/Admin/Quan_ly_tai_khoan")
);
const LichSuThanhToan = React.lazy(
  () => import("../components/organisms/pages/Admin/Lich_su_thanh_toan")
);
const TraCuuThongTin = React.lazy(
  () => import("../components/organisms/pages/Admin/Tra_cuu_Thong_tin")
);
const NhatKyVeThang = React.lazy(
  () => import("../components/organisms/pages/Admin/Nhat_ky_ve_thang")
);
export const routePortalsAdmin = {
  QUAN_LY_THE_XE: "/quan-ly-the-xe",
  QUAN_LY_TAI_KHOAN: "/quan-ly-tai-khoan",
  DANG_KY_GIA_HAN: "/dang-ky-gia-han-ve-xe",
  // QUAN_LY_THANH_TOAN: "/quan-ly-thanh-toan",
  THONG_KE_DOANH_THU: "/thong-ke-doanh-thu",
  CAU_HINH_THANH_TOAN: "/cau_hinh_thanh_toan",
  LICH_SU_THANH_TOAN: "/lich_su_thanh_toan",
  TRA_CUU_THONG_TIN: "/tra-cuu-thong-tin",
  NHAT_KY_VE_THANG: "/nhat-ky-ve-thang",
};
export const routePortalAdmin = [
  {
    id: 10,
    route: routePortalsAdmin.QUAN_LY_THE_XE,
    component: quanLiTheXe,
  },

  {
    id: 12,
    route: routePortalsAdmin.DANG_KY_GIA_HAN,
    component: dangKyGiaHan,
  },

  {
    id: 13,
    route: routePortalsAdmin.THONG_KE_DOANH_THU,
    component: thongKeDoanhThu,
  },
  {
    id: 14,
    route: routePortalsAdmin.CAU_HINH_THANH_TOAN,
    component: cauHinhThanhToan,
  },

  {
    id: 15,
    route: routePortalsAdmin.QUAN_LY_TAI_KHOAN,
    component: quanLyTaiKhoan,
  },
  {
    id: 16,
    route: routePortalsAdmin.LICH_SU_THANH_TOAN,
    component: LichSuThanhToan,
  },

  {
    id: 17,
    route: routePortalsAdmin.TRA_CUU_THONG_TIN,
    component: TraCuuThongTin,
  },
  {
    id: 18,
    route: routePortalsAdmin.NHAT_KY_VE_THANG,
    component: NhatKyVeThang,
  },
];
