import React, { Suspense, useEffect, useState } from "react";
import "./style.css";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Spin, ConfigProvider } from "antd";
import { routePortal, routePortals } from "../routes/PortalRoute";
import LayoutPage from "../components/layout/LayoutPage";
import {
  routePortalAdmin,
  routePortalsAdmin,
} from "../routes/PortalRouteAdmin";
import { token } from "../configs/localStorage";
import { GetStatusUseOnlinePayment } from "../apis/apis";
const GiaHanTheXe = React.lazy(
  () => import("../components/organisms/pages/Admin/GiaHanTheXe")
);
const NotFound = React.lazy(
  () => import("../components/organisms/pages/Notfound")
);
function App() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) navigate(routePortals.DANG_KY_THE_XE);
    if (location.pathname === "/" && !token) {
      navigate(routePortals.DANG_KY_THE_XE);
    }
    if (location.pathname === "/" && token) {
      navigate(routePortalsAdmin.QUAN_LY_THE_XE);
    }
  }, [token]);
  const [IsStatusShowPayment, setIsStatusShowPayment] = useState(false);
  const getStatusSwitch = async () => {
    try {
      const res = await GetStatusUseOnlinePayment();
      if (res?.data) {
        setIsStatusShowPayment(res?.data?.message === 1 ? true : false);
        // if (res?.data?.message !== 1 || !token) {
        //   {
        //     navigate(routePortals.DANG_KY_THE_XE);
        //   }
        // }
      }
    } catch (e) {
    } finally {
    }
  };
  useEffect(() => {
    getStatusSwitch();
  }, []);
  return (
    <div className="App bg-[#F0F8FF]   ">
      <Suspense
        fallback={
          <Spin
            className="w-full h-[80vh] flex items-center justify-center "
            size="large"
          />
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              <LayoutPage>
                <Outlet />
              </LayoutPage>
            }
          >
            <Route path="/">
              {routePortal.map((route) => (
                <Route
                  key={route.id}
                  path={route.route}
                  element={<route.component />}
                ></Route>
              ))}

              {token &&
                routePortalAdmin.map((route) => (
                  <Route
                    key={route.id}
                    path={route.route}
                    element={<route.component />}
                  ></Route>
                ))}
              {IsStatusShowPayment || token ? (
                <Route
                  path={routePortals.GIA_HAN_THE_XE}
                  element={<GiaHanTheXe />}
                />
              ) : (
                ""
              )}

              <Route path={"*"} element={<NotFound />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
